import React from 'react';

import Grid from '@mui/material/Grid';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout, SEO } from '../components/common';
import { Container, PageWrapper, Details } from '../styles';
import { Header } from '../components/theme';
import ContributorsCard from '../components/contributors/ContributorsCard';

const Contributors = function () {
  const contributorsData = useStaticQuery(graphql`
    query ContributorsImages {
      jefferyImage: file(relativePath: { eq: "jeffery_img.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO />
      <Header />
      <PageWrapper as={Container}>
        <Details>
          <h1>Website Contributors</h1>
          <Grid container spacing={2}>
            <Grid item xl={4} md={4} sm={6}>
              <ContributorsCard
                query={contributorsData.jefferyImage.childImageSharp.gatsbyImageData}
                name="Jeffery Wei"
                title="Developer"
                project="Web Development"
                bio="I'm a freshman at UMD majoring in Computer Science and Mathematics. I've been involved with
                website development since 9th grade and am interested in specializing in machine learning at college. At MIND
                Lab, I've done work in both the website development and breathing analysis projects. I hope to found a startup
                in the future, but if that doesn't work, I'd love to work in either quantitative finance or the AI world!"
                year="Undergradate"
                showLink={false}
                linkTitle="Website"
                link=""
              />
            </Grid>
          </Grid>
        </Details>
      </PageWrapper>
    </Layout>
  );
};

export default Contributors;
