import React from 'react'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

import { GatsbyImage } from 'gatsby-plugin-image';

import '../../styles/components/ContributorsCard.css';

const ContributorsCard = function (props) {
    const showLink = props.showLink;

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia>
                <GatsbyImage className="contributor_card_img"
                image={props.query}
                />
           </CardMedia>
           
            <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {props.name}
            </Typography>
            <Typography gutterBottom variant="body1" component="div">
                {props.title}
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="div">
                {props.project}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {props.bio}
            </Typography>
            </CardContent>  
            <CardActions>
                <Typography className="year" variant="subtitle2" color="text.secondary">
                    {props.year}
                </Typography>
                {showLink && <Button size="small" href={props.link}>{props.linkTitle}</Button>}
            </CardActions>
        </Card>
    );
  };
  
  export default ContributorsCard;